<template>
  <div class="view-fusion">
    <div class="back-btn-box">
      <div @click="$router.push('/newuser?TabIndex=1')" class="back-btn point">
        <div class="img"></div>
        <div class="text">返回背包</div>
      </div>
    </div>
    <div class="center-box">
      <div class="left-box">
        <div @click="chooseAllOnClick" class="choose-all-box flex-c point">
          <div class="check-box">
            <img
              v-show="!isSelectAll"
              src="@/assets/images/about/bag/check.png"
              alt=""
              style="width: 0.14rem; margin-right: 0.12rem" />
            <img
              v-show="isSelectAll"
              src="@/assets/images/about/bag/checked.png"
              alt=""
              style="width: 0.14rem; margin-right: 0.12rem" />
          </div>
          <div class="check-text">全选当前页</div>
        </div>
        <div class="backpack-box">
          <div
            v-for="item in backpackList"
            :key="item.id"
            class="backpack-item"
            @click="chooseItemOnClick(item)">
            <GoodItem
              :item="item"
              :isActive="chooseGoodsIdList.includes(item.id)" />
          </div>
        </div>
        <div class="page-box">
          <Pagination
            :pager-count="3"
            :total="page_total"
            :page.sync="page_num"
            :limit.sync="per_page"
            layout="prev, pager, next"
            @pagination="handleCurrentChange" />
        </div>
      </div>
      <div class="center-box-in">
        <div class="center-top">
          <div class="animation-box">
            <div class="bg3 flex-c">
              <img
                style="height: 100%"
                src="@/assets/images/new/fusion-bg3.png"
                alt=""
                srcset="" />
            </div>
            <div v-if="showGif" class="bg2 flex-c">
              <img style="height: 100%" :src="srcUrl" alt="" srcset="" />
            </div>
            <div ref="bg5" class="bg5 flex-c" v-if="cacheFusionItem.cover">
              <img
                style="height: 100%"
                :src="cacheFusionItem.cover"
                alt=""
                srcset="" />
            </div>
            <div class="bg4 flex-c" v-if="showBg4">
              <img
                style="height: 100%"
                src="@/assets/images/new/fusion-bg4.png"
                alt=""
                srcset="" />
            </div>
          </div>
          <div class="btn-box flex-c">
            <div @click="fusionBtnOnClick" class="btn flex-c point">
              开始熔炼
            </div>
          </div>
        </div>
        <div class="center-bottom">
          <div v-if="false" class="empty-box">
            <img
              class="img"
              src="@/assets/images/newuser/mb-table-nulldata.png"
              alt="" />
            <div class="nulltext">暂无内容</div>
          </div>
          <div v-else class="choosed-box">
            <div
              v-for="(item, index) in chooseGoodsList"
              :key="item.id"
              class="choosed-item">
              <GoodItem
                @closeOnClick="emitCloseOnClick(index)"
                showCloseBtn
                :item="item"
                :isActive="chooseGoodsIdList.includes(item.id)" />
            </div>
          </div>
          <div class="btn-box flex-c">
            <div @click="choosedOnClick" class="btn flex-c point">确定选择</div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <div class="show-box-wrap">
          <div class="show-box">
            <div class="img-box">
              <img
                style="height: 100%"
                :src="fusionItem.cover"
                alt=""
                srcset="" />
            </div>
          </div>
        </div>
        <div class="tab-box">
          <div class="tab1-list">
            <div
              :class="{ 'tab1-item-active': select1 === item.value }"
              class="tab1-item point"
              @click="select1ListOnClick(item)"
              v-for="item in select1List"
              :key="item.value">
              {{ item.label }}
            </div>
          </div>
          <div class="tab2-list">
            <div
              :class="{ 'tab2-item-active': select2 === item.value }"
              @click="select2ListOnClick(item)"
              class="tab2-item point"
              v-for="item in select2List"
              :key="item.value">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="choosed-box">
          <div v-if="!fusionGoodList.length" class="empty-box">
            <img class="img" src="@/assets/images/new/empty-icon.png" alt="" />
            <div class="nulltext">暂无内容</div>
          </div>
          <div
            v-else
            @click="fusionGoodOnClick(item)"
            v-for="item in fusionGoodList"
            :key="item.id"
            class="choosed-item">
            <GoodItem :isActive="fusionItem.id === item.id" :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodItem from "./components/goodItem.vue";
import {
  Storage,
  fusionGoodListApi,
  luckyType,
  newOldType,
  fusionApi,
} from "@/network/api.js";
import { uniqBy } from "lodash";
export default {
  components: {
    GoodItem,
  },
  data() {
    return {
      tabIndex: 0,
      popupShow: false,
      page_num: 1,
      per_page: 15,
      page_total: 1,
      backpackList: [],
      select1: "all",
      select1List: [],
      select2: "all",
      select2List: [],
      chooseGoodsList: [],
      showGif: false,
      showBg4: false,
      srcUrl: "",
      fusionGoodList: [],
      fusionItem: {},
      cacheFusionItem: {},
    };
  },
  created() {
    this.getLuckyType();
    this.getNewOldType();
    this.getStorageFun();
  },
  computed: {
    chooseGoodsIdList() {
      return this.chooseGoodsList.map((item) => item.id);
    },
    isSelectAll() {
      let flag = true;
      this.backpackList.forEach((item) => {
        if (!this.chooseGoodsIdList.includes(item.id)) {
          flag = false;
        }
      });
      return flag;
    },
  },
  watch: {
    chooseGoodsIdList: {
      handler() {
        this.fusionItem = {};
        this.getFusionGoodList();
      },
      deep: true,
      immediate: true,
    },
    select1: {
      handler() {
        this.getFusionGoodList();
      },
    },
    select2: {
      handler() {
        this.getFusionGoodList();
      },
    },
  },
  methods: {
    chooseAllOnClick() {
      if (this.isSelectAll) {
        this.chooseGoodsList = this.chooseGoodsList.filter((item) => {
          return !this.backpackList
            .map((vItem) => {
              return vItem.id;
            })
            .includes(item.id);
        });
      } else {
        const cacheList = uniqBy(
          [...this.chooseGoodsList, ...this.backpackList],
          "id"
        );
        if (cacheList.length > 20) {
          this.$message.error("最多选择20个饰品");
          return false;
        } else {
          this.chooseGoodsList = cacheList;
        }
      }
    },
    select1ListOnClick(item) {
      this.select1 = item.value;
    },
    select2ListOnClick(item) {
      this.select2 = item.value;
    },
    async getLuckyType() {
      const res = await luckyType();
      this.select1List = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.select1List.unshift({
        label: "全部",
        value: "all",
      });
    },
    async getNewOldType() {
      const res = await newOldType();
      this.select2List = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.select2List.unshift({
        label: "全部",
        value: "all",
      });
    },
    chooseOnClick() {
      this.popupShow = false;
    },
    fusionGoodOnClick(item) {
      console.log(123, item);
      this.fusionItem = item;
    },
    choosedOnClick() {
      this.$message({
        message: "您点击了确认选择",
        type: "success",
      });
      this.getFusionGoodList;
    },
    async getFusionGoodList() {
      if (this.chooseGoodsIdList.length < 2) {
        this.fusionGoodList = [];
        return false;
      }
      const params = {
        type: this.select1 === "all" ? "" : this.select1,
        dura: this.select2 === "all" ? "" : this.select2,
        in_ids: this.chooseGoodsIdList,
      };
      const res = await fusionGoodListApi(params);
      if (res.data?.code === 200) {
        this.fusionGoodList = res?.data?.data || [];
      } else {
        this.$message({
          message: res.data.message,
          type: "error",
        });
      }
    },
    async fusionIngFun() {
      const params = {
        in_ids: this.chooseGoodsIdList,
        skins_id: this.fusionItem.id,
        // skins_id: 7455,
      };
      const res = await fusionApi(params);
      if (res?.data?.code === 200) {
        this.$message.success("熔炼成功");
        return true;
      } else {
        this.$message.error(res.data.message);
        return false;
      }
    },
    palyFusionAudio() {
      // 创建一个新的Audio对象
      var audio = new Audio(require("@/assets/audios/ronglian.wav"));
      audio.play();
    },
    async fusionBtnOnClick() {
      if (!this.fusionItem.id) {
        this.$message({
          message: "请在右侧选择要融合的物品",
          type: "error",
        });
        return false;
      }
      const res = await this.fusionIngFun();
      if (!res) {
        return false;
      }
      this.palyFusionAudio();
      this.showGif = true;
      this.srcUrl = require("../../assets/images/new/fusion-bg1.gif");
      setTimeout(() => {
        this.srcUrl = require("../../assets/images/new/opycity0.png");
        this.showBg4 = true;
        this.chooseGoodsList = [];
        this.fusionGoodList = [];
        this.fusionItem = {};
      }, 1000);
      setTimeout(() => {
        document.querySelector(".bg5")?.classList.remove("expand");
        document.querySelector(".bg5").classList.remove("float-ud");
      }, 0);
      setTimeout(() => {
        document.querySelector(".bg5").classList.add("expand");
      }, 500);
      setTimeout(() => {
        document.querySelector(".bg5").classList.add("float-ud");
      }, 1600);
      this.cacheFusionItem = this.fusionItem;
      this.getStorageFun();
    },
    emitCloseOnClick(index) {
      console.log(666, index);
      this.chooseGoodsList.splice(index, 1);
    },
    chooseItemOnClick(item) {
      if (!this.chooseGoodsIdList.includes(item.id)) {
        if (this.chooseGoodsIdList.length >= 20) {
          this.$message({
            message: "最多选择20个物品",
            type: "error",
          });
          return false;
        }
        this.chooseGoodsList.push(item);
      } else {
        this.chooseGoodsList = this.chooseGoodsList.filter(
          (vItem) => vItem.id !== item.id
        );
      }
      console.log(22222, this.chooseGoodsList);
    },
    handleCurrentChange(e) {
      console.log(123, e);
      this.page = e.page;
      this.getStorageFun();
    },
    // chooseGoodsOnClick() {
    //   this.popupShow = true;
    //   this.getStorageFun();
    // },
    closeOnClick() {
      this.popupShow = false;
      //   this.chooseGoodsList = [];
    },
    getStorageFun() {
      Storage(this.page_num, 1, 0, this.per_page).then((res) => {
        console.log(res);
        this.backpackList = res.data.data.data;
        this.page_total = res.data.data.total;
        console.log(111, this.backpackList);
      });
    },
  },
};
</script>

<style lang="scss">
.view-fusion {
  padding: 0 2.18rem;
  padding-top: 0.38rem;

  .back-btn-box {
    margin-bottom: 0.24rem;
    .back-btn {
      // width: 1.3rem;
      // height: 0.38rem;
      padding: 0.08rem 0.22rem;
      width: fit-content;
      background: #5638cc;
      border: 0.01rem solid #8684ff;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 0.16rem;
        height: 0.16rem;
        background: url(../../assets/images/new/left-arrow.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 0.06rem;
      }
    }
  }
  .center-box {
    display: flex;
    height: 10.4rem;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    .left-box {
      width: 4.8rem;
      height: 100%;
      // height: 10.03rem;
      // background: pink;
      flex-shrink: 0;
      padding: 0.16rem 0rem 0.16rem 0.16rem;
      border: 0.02rem solid #2f2f5e;
      .choose-all-box {
        // position: absolute;
        // top: 0;
        // right: 0.1rem;
        height: 0.36rem;
        font-size: 0.16rem;
        color: #8a8ace;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        .check-box {
          padding-top: 0.03rem;
        }
      }
      .backpack-box {
        width: 100%;
        // height: 9.2rem;
        // margin-bottom: 0.12rem;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        .backpack-item {
          width: 1.4rem;
          // height: 1.68rem;
          // background: #ccc;
          margin-right: 0.077rem;
          margin-bottom: 0.16rem;
        }
        .backpack-item:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      .page-box {
        width: 100%;
        height: 0.29rem;
        // padding: 0 0.2rem;
        // background: skyblue;
        margin-left: -0.16rem;
      }
    }
    .center-box-in {
      width: 4.48rem;
      height: 100%;
      // height: 2rem;
      // background: skyblue;
      flex-shrink: 0;
      position: relative;
      .center-top {
        width: 5.24rem;
        height: 4.2rem;
        position: relative;
        left: -0.38rem;
        .animation-box {
          width: 100%;
          height: 100%;
          position: relative;
          .bg3 {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0rem;
            top: 0rem;
          }
          .bg2 {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0rem;
            top: 0rem;
          }
          .bg4 {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0rem;
            top: 0rem;
          }
          .bg5 {
            width: 100%;
            height: 2.9rem;
            position: absolute;
            left: 0rem;
            top: 0.4rem;
            transform: scale(0);
            transition: transform 1s ease-in-out;
          }
          /* 定义一个名为floatEffect的关键帧动画 */
          @keyframes floatEffect {
            0%,
            100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-0.25rem); /* 根据需要调整浮动的距离 */
            }
          }

          /* 应用该动画到想要浮动的元素，例如一个图片 */
          .float-ud {
            animation: floatEffect 2.5s ease-in-out infinite; /* 动画名称、持续时间、速度曲线、循环次数 */
          }
          .expand {
            transform: scale(1);
          }
          // background: #ccc;
        }
        .btn-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0.16rem;
          left: 50%;
          transform: translateX(-50%);
          .btn {
            width: 1.76rem;
            height: 0.42rem;
            background: url(../../assets/images/new/fusion-bg6.png) no-repeat;
            background-size: 100% 100%;
          }
          .btn {
            width: 1.76rem;
            height: 0.42rem;
            background: url(../../assets/images/new/fusion-bg7.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .center-bottom {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0.16rem 0rem 0.22rem 0.16rem;
        // background: skyblue;
        border: 0.02rem solid #2f2f5e;
        .choosed-box {
          width: 100%;
          height: 5.6rem;
          margin-bottom: 0.02rem;
          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
          overflow-y: auto;

          .choosed-item {
            width: 1.28rem;
            // height: 1.54rem;
            // background: #ccc;
            margin-right: 0.14rem;
            margin-bottom: 0.16rem;
          }
          .choosed-item:nth-of-type(3n) {
            margin-right: 0;
          }
        }
        .btn-box {
          width: 100%;
          opacity: 0;
          height: 0;
          .btn {
            width: 1.68rem;
            height: 0.4rem;
            background: #151533;
            border: 0.014rem solid #5d5d9a;
          }
        }
      }
    }
    .right-box {
      width: 4.8rem;
      height: 100%;
      // height: 2rem;
      // background: pink;
      flex-shrink: 0;
      padding: 0.16rem 0;
      border: 0.02rem solid #2f2f5e;
      .show-box-wrap {
        padding: 0 0.16rem;
        width: 100%;
        height: 2.9rem;
        // background: skyblue;
        margin-bottom: 0.14rem;
        padding: 0 0.16rem;
        .show-box {
          width: 100%;
          height: 2.9rem;
          border: 0.02rem solid #2f2f5e;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url(../../assets/images/new/fusion-bg6.png) no-repeat;
          background-size: 3.08rem 2.67rem;
          background-position-x: 0.62rem;
          background-position-y: -0.1rem;
          .img-box {
            width: 3.6rem;
            height: 2.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .tab-box {
        padding: 0 0.3rem;
        font-size: 0.12rem;
        color: #736c90;
        .tab1-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.32rem;
          .tab1-item {
            margin-right: 0.36rem;
            flex-shrink: 0;
            margin-bottom: 0.12rem;
            padding-bottom: 0.04rem;
            border-bottom: 0.02rem solid rgba(255, 0, 0, 0);
          }
          .tab1-item-active {
            color: #a99be2;
            border-bottom: 0.02rem solid #a99be2;
          }
        }
        .tab2-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.04rem;
          .tab2-item {
            margin-right: 0.36rem;
            margin-bottom: 0.12rem;
            flex-shrink: 0;
            padding-bottom: 0.04rem;
            border-bottom: 0.02rem solid rgba(255, 0, 0, 0);
          }
          .tab2-item-active {
            color: #a99be2;
            border-bottom: 0.02rem solid #a99be2;
          }
        }
      }
      .choosed-box {
        padding: 0 0 0 0.16rem;
        width: 100%;
        height: 5.3rem;
        // margin-bottom: 0.32rem;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-y: auto;

        .choosed-item {
          width: 1.4rem;
          // height: 1.68rem;
          // background: #ccc;
          margin-right: 0.14rem;
          margin-bottom: 0.16rem;
        }
        .choosed-item:nth-of-type(3n) {
          margin-right: 0;
        }
        .empty-box {
          width: 100%;
          height: 3.5rem;
          // border: 0.02rem solid #2f2f5e;
          padding: 0.6rem 1.28rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: flex-start;
          .img {
            width: 1.6rem;
            height: 1.6rem;
            margin-bottom: 0.1rem;
          }
          .nulltext {
            width: 100%;
            font-size: 0.16rem;
            color: #c5bbed;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
